import { unlockBodyScroll } from '@/utils/dom';
import { timeout } from '@/utils/timeout';

export const curtain = (): Promise<void> =>
    new Promise(async (resolve) => {
        document.body.classList.add('is-page-leaving');
        await timeout(1000);
        document.body.classList.remove('is-page-leaving');
        resolve();
    });
