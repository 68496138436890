import { usePresence } from 'framer-motion';
import { useEffect, useTransition } from 'react';
import {
    DEFAULT_MODE,
    DEFAULT_NAME,
    PageTransitionName,
    useIsPageTransitioningState,
    usePageTransitionState,
} from '@/atoms/page-transition';
import { curtain } from './base';
import { leaveInstant } from './leave-instant';
import { lockBodyScroll, unlockBodyScroll } from '@/utils/dom';

export type LeaveFn = (data: { targetElement?: Element | null }) => Promise<void>;

const leaveFnMap: Record<PageTransitionName, LeaveFn> = {
    default: curtain,
    instant: leaveInstant,
};

export const usePageTransition = () => {
    const [{ name }, setLeaveTransition] = usePageTransitionState();
    const [isPresent, safeToRemove] = usePresence();
    const [isTransitioning, setIsTransitioning] = useIsPageTransitioningState();
    const [, startTransition] = useTransition();

    useEffect(() => {
        if (!isPresent && !isTransitioning) {
            setIsTransitioning(true);
            lockBodyScroll();

            leaveFnMap[name]({})
                .then(() => {
                    safeToRemove();
                    setLeaveTransition({ mode: DEFAULT_MODE, name: DEFAULT_NAME });
                })
                .finally(() => {
                    startTransition(() => {
                        setIsTransitioning(false);
                        unlockBodyScroll();
                    });
                });
        }
    }, [setLeaveTransition, isPresent, name, safeToRemove, isTransitioning, setIsTransitioning]);
};
